import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import logo from "../assets/logo.png";
import styles from "./styles/NavBar.module.css";

const NavBar = () => {

  return (
    <Navbar className={styles.navbar} sticky="top" collapseOnSelect expand="lg">
        <Navbar.Brand href="https://testdriveds.ca">
          <img className={styles.logo} loading="eager" alt="" src={logo} />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={styles.buttonGroup}>
            <Nav.Link
              className={styles.buttonLink}
              href="https://testdriveds.ca"
            >
              TestDrive Driving School
            </Nav.Link>
            <Nav.Link
              className={styles.buttonLink}
              href="https://blog.testdriveds.ca"
            >
              Blog
            </Nav.Link>
            <Nav.Link className={styles.buttonLink} href="#home">
              G1 Practice Test
              <svg
                width="100%"
                height="8px"
                viewBox="0 0 20 10"
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="-3.05176e-05"
                  y1="2"
                  x2="40"
                  y2="2"
                  stroke="#019317"
                  stroke-width="4"
                />
              </svg>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
