import styles from "./styles/description.module.css";
import descriptionLogoIcon from "../assets/descriptionlogo.svg"

const Description = (props) => {
  return (
    <div className={styles.description}>
      <img
        className={styles.descriptionLogoIcon}
        alt=""
        src= {descriptionLogoIcon}
      />
      <i className={styles.regularText}>
        {props.text}
      </i>
    </div>
  );
};

export default Description;