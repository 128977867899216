import { Link } from 'react-router-dom';

import instagram from "../assets/Instagram.svg";
import whatsapp from "../assets/Whatsapp.svg";
import styles from "./styles/footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
        <div className={[styles.footerTitle, styles.title].join(" ")}>Contact Us</div>
        <div className={styles.footerContent}>
          <div className={styles.footerContentItem}>
            <p className={[styles.footerContentRowItem, styles.regularText].join(" ")}>Phone:</p>
            <p className={[styles.footerContentRowItem, styles.regularText].join(" ")}>647-291-8779</p>
          </div>
          <div className={styles.footerContentItem}>
            <p className={[styles.footerContentRowItem, styles.regularText].join(" ")}>Website:</p>
            <Link style={{cursor: "pointer"}} className={[styles.footerContentRowItem, styles.regularText].join(" ")} to="https://testdriveds.ca">
              https://www.testdriveds.ca
              </Link>
          </div>
          <div className={styles.footerContentItem}>
            <p className={[styles.footerContentRowItem, styles.regularText].join(" ")}>Email:</p>
            <p className={[styles.footerContentRowItem, styles.regularText].join(" ")}>support@testdriveds.ca</p>
          </div>
          <div className={styles.footerContentItem}>
            <p className={[styles.footerContentRowItem, styles.regularText].join(" ")}>Address:</p>
            <p className={[styles.footerContentRowItem, styles.regularText].join(" ")}>Greater Toronto Area</p>
          </div>
        </div>
        <div className={styles.footerSocials}>

          <div>
          <a href='https://instagram.com/testdriveds.ca?igshid=ZGUzMzM3NWJiOQ==' >
            <img className={styles.icon} src={instagram} alt="instagram"/>
          </a>
            
          </div>
          <div>
          <a href='https://wa.me/c/16472918779' >
            <img className={styles.icon} src={whatsapp} alt="whatsapp"/>
          </a>
          </div>
        </div>
      </footer>
  );
};

export default Footer;
