import { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Description from "../components/Description";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import OptionsMenu from "../components/OptionsMenu";
import { trafficRuleQuestions, trafficSignQuestions } from "../components/questions.js";
import styles from "./styles/home.module.css";

const Home = () => {
  /**************************** States ****************************/
  const navigate = useNavigate();
  const [numOfTrafficSignQuestions, setNumOfTrafficSignQuestions] =
    useState(20);
  const [numOfTrafficRuleQuestions, setNumOfTrafficRuleQuestions] =
    useState(20);
  const [timerValue, setTimerValue] = useState(30);

  const [quickTest, setQuickTest] = useState(false);
  const [examSimulation, setExamSimulation] = useState(true);
  const [customTest, setCustomTest] = useState(false);
  const [timed, setTimed] = useState(true);


  /**************************** Functions ****************************/
  /******* fetchDataFunction *******/
  const fetchDataFunction = async (localStorageItem) => {
    try {
      // Fetch the data from local storage
      const data = await localStorage.getItem(localStorageItem);
      
      // Parse the data if it exists
      const parsedData = data ? JSON.parse(data) : null;
  
      return parsedData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  /******* initQuestionsList *******/
  const initQuestionsList = async () => {
    const trafficRuleQuestions = await fetchDataFunction("trafficRuleQuestions");
    const trafficSignQuestions = await fetchDataFunction("trafficSignQuestions");

    const trafficSignQuestionsList = [];
    const trafficRuleQuestionsList = [];
    let min = 0;
    let max = trafficSignQuestions.length - 1;

    //Traffic Sign Questions
    for (let i = 0; i < numOfTrafficSignQuestions; i++) {
      const randomBuffer = new Uint32Array(1);
      let index;
      do {
        window.crypto.getRandomValues(randomBuffer);
        let randomNumber = randomBuffer[0] / (0xffffffff + 1);
        index = Math.floor(randomNumber * (max - min + 1)) + min;
      } while (trafficSignQuestionsList.includes(index));

      trafficSignQuestionsList.push(index);
    }

    max = trafficRuleQuestions.length - 1;
    //Traffic Rule Questions
    for (let i = 0; i < numOfTrafficRuleQuestions; i++) {
      const randomBuffer = new Uint32Array(1);
      let index;
      do {
        window.crypto.getRandomValues(randomBuffer);
        let randomNumber = randomBuffer[0] / (0xffffffff + 1);
        index = Math.floor(randomNumber * (max - min + 1)) + min;
      } while (trafficRuleQuestionsList.includes(index));

      trafficRuleQuestionsList.push(index);
    }

    // Combine both lists
    const combinedQuestionsList = [
      ...trafficSignQuestionsList,
      ...trafficRuleQuestionsList,
    ];

    // return list
    return combinedQuestionsList;
    
  };

  /******* startTestButtonClick *******/
  const startTestButtonClick = async () => {
    let questionsList = await initQuestionsList();

    navigate("/quiz", {
      state: {
        numOfTrafficSignQuestions,
        numOfTrafficRuleQuestions,
        timed,
        timerValue,
        questionsList
      },
    });
  };

  const HandleQuickTest = () => {
    if (!quickTest) {
      setQuickTest(!quickTest);
      setExamSimulation(false);
      setCustomTest(false);
      setNumOfTrafficRuleQuestions(10);
      setNumOfTrafficSignQuestions(10);
    }
  };

  const HandleExamSimulation = () => {
    if (!examSimulation) {
      setExamSimulation(!examSimulation);
      setQuickTest(false);
      setTimed(true);
      setCustomTest(false);
      setNumOfTrafficRuleQuestions(20);
      setNumOfTrafficSignQuestions(20);
    }
  };

  const HandleCustomTest = () => {
    if (!customTest) {
      setCustomTest(!customTest);
      setExamSimulation(false);
      setQuickTest(false);
      setTimed(true);
    }
  };

  const HandleTimed = () => {
    if (!examSimulation) {
      setTimed(!timed);
    }
  };

  /**************************** Render ****************************/
  return (
    <div className={styles.home}>
      {/**************************** NavBar ****************************/}
      <NavBar />
      {/**************************** Body ****************************/}
      <main className={styles.body}>
        {/**************************** InfoSection ****************************/}
        <section className={styles.infoSection}>
          <div className={styles.titleGroup}>
            <h1 className={styles.heading}>G1 Practice Test</h1>
            <h2 className={styles.subheading}>Presented by TestDriveDS.ca</h2>
          </div>

          <div className={styles.descriptionGroup}>
            <Description text="Prepare for success on your Ontario G1 knowledge test with this comprehensive G1 practice test." />
            <Description text="Select between a quick test (20 questions) or an exam simulation (40 questions in 30 minutes)." />
            <Description text="Try beating the clock or take your time in quick or custom tests." />
            <Description text="Build a custom test and be able to select the amount and type of questions." />
            <Description text="Score a minimum of 80% on each section to pass." />
          </div>
        </section>
        {/**************************** OptionsSection ****************************/}
        <section className={styles.optionsSection}>
          <OptionsMenu
            numOfTrafficRuleQuestions={numOfTrafficRuleQuestions}
            numOfTrafficSignQuestions={numOfTrafficSignQuestions}
            quickTest={quickTest}
            customTest={customTest}
            examSimulation={examSimulation}
            timerValue={timerValue}
            timed={timed}
            HandleQuickTestFunction={HandleQuickTest}
            HandleExamSimulationFunction={HandleExamSimulation}
            HandleCustomTestFunction={HandleCustomTest}
            HandleTimedFunction={HandleTimed}
            setNumOfTrafficSignQuestionsFunction={setNumOfTrafficSignQuestions}
            setNumOfTrafficRuleQuestionsFunction={setNumOfTrafficRuleQuestions}
            setTimerValueFunction={setTimerValue}
          />
        </section>
        {/**************************** StartTestButton ****************************/}

        <Button
          variant="success"
          onClick={startTestButtonClick}
          className={[styles.startTestButton, styles.title].join(" ")}
        >
          START
        </Button>
      </main>
      <Footer />
    </div>
  );
};

export default Home;
