import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import ResultGraph from "../components/ResultGraph";
import styles from "./styles/result.module.css";

import "bootstrap/dist/css/bootstrap.min.css";

const Result = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    numOfTrafficSignQuestions,
    numOfTrafficRuleQuestions,
    numOfSignQuestionsCorrect,
    numOfRuleQuestionsCorrect,
  } = location.state;
  const score = Math.round(
    ((parseInt(numOfSignQuestionsCorrect) +
      parseInt(numOfRuleQuestionsCorrect)) /
      (parseInt(numOfTrafficSignQuestions) +
        parseInt(numOfTrafficRuleQuestions))) *
      100
  );

  const signsScore =
    numOfTrafficSignQuestions > 0
      ? Math.round(
          (numOfSignQuestionsCorrect / numOfTrafficSignQuestions) * 100
        )
      : 100;
  const rulesScore =
    numOfTrafficRuleQuestions > 0
      ? Math.round(
          (numOfRuleQuestionsCorrect / numOfTrafficRuleQuestions) * 100
        )
      : 100;

  const pass = score >= 80 && signsScore >= 80 && rulesScore >= 80;

  const HandleReturnHome = () => {
    navigate("/");
  };

  const getReason = () => {
    if(parseInt(numOfTrafficRuleQuestions) > 0 && rulesScore < 80 && parseInt(numOfTrafficSignQuestions) > 0 && signsScore < 80)
      return "You did not achieve the mimimum of 80% on the traffic rules and traffic signs sections within time."
    else
    if (parseInt(numOfTrafficRuleQuestions) > 0 && rulesScore < 80)
      return "You did not achieve the mimimum of 80% on the traffic rules section within time."
    else
    if (parseInt(numOfTrafficSignQuestions) > 0 && signsScore < 80)
      return "You did not achieve the mimimum of 80% on the traffic signs section within time."
  };

  return (
    <div className={styles.result}>
      {/**************************** Navbar ****************************/}
      <NavBar />

      {/**************************** Body ****************************/}
      <body className={styles.body}>
        {/**************************** statsSection ****************************/}
        <section className={styles.statsSection}>
          <div className={styles.generalScoreGroup}>
            <h1
              className={styles.heading}
              style={{ color: pass ? "green" : "red", fontWeight: 800 }}
            >
              {pass ? "You Have Passed" : "Unfortunately, you did not pass"}
            </h1>

            <h2 className={styles.subheading}>You Scored {score}% Overall.</h2>
          </div>

          {/**************************** Graphs ****************************/}
          <div className={styles.specificScoreGroup}>
            {numOfTrafficSignQuestions > 0 ? (
              <ResultGraph
                section={"Traffic Signs"}
                score={signsScore}
                correct={numOfSignQuestionsCorrect}
                wrong={numOfTrafficSignQuestions - numOfSignQuestionsCorrect}
              />
            ) : (
              <div style={{display:"none"}}></div>
            )}

            {numOfTrafficRuleQuestions > 0 ? (
              <ResultGraph
                section={"Traffic Rules"}
                score={rulesScore}
                correct={numOfRuleQuestionsCorrect}
                wrong={numOfTrafficRuleQuestions - numOfRuleQuestionsCorrect}
              />
            ) : (
              <div style={{display:"none"}}></div>
            )}
          </div>
        </section>

        {/**************************** buttonsSection ****************************/}
        <section className={styles.nextStepsSection}>
          <text className={styles.boldText}>{getReason()}</text>
          {pass ? (
            <div className={styles.regularText}>
              Ready for your G1? Click here to find your next steps! <br/>
              Make sure to practice at least 3 times for better results on the actual G1 Test.
            </div>
          ) : (
            <div className={styles.regularText}>
              Click down below to retake the test.
            </div>
          )}

          {pass ? (
            <div className={styles.buttonGroup}>
              <Button
                className={styles.learnMoreButton}
                onClick={() => {
                  window.open(
                    "https://blog.testdriveds.ca/gettingG1.html",
                    "_blank"
                  );
                }}
                variant="success"
              >
                Learn More
              </Button>
              <Button
                className={styles.continuePracticingButton}
                onClick={HandleReturnHome}
                variant="success"
              >
                Continue Practicing
              </Button>
            </div>
          ) : (
            <Button
              className={styles.continuePracticingButton}
              onClick={HandleReturnHome}
              variant="success"
            >
              Retake Test
            </Button>
          )}
        </section>
      </body>
      <Footer />
    </div>
  );
};

export default Result;
