import { useEffect, useState } from "react";
import { Spinner } from "react-activity";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import { trafficRuleQuestions, trafficSignQuestions } from "../components/questions.js";
import StatusGroup from "../components/StatusGroup.js";
import styles from "./styles/quiz.module.css";

import "react-activity/dist/library.css";

const Quiz = () => {
  /**************************** States ****************************/
  const navigate = useNavigate();
  const location = useLocation();
  const {
    numOfTrafficSignQuestions,
    numOfTrafficRuleQuestions,
    timed,
    timerValue,
    questionsList,
  } = location.state;

  const [maxTimerValue] = useState(timerValue);
  const [currentTimerValue, setCurrentTimerValue] = useState(timerValue);

  const [currentQuestion, setCurrentQuestion] = useState({
    Question: null,
    Image: null,
    OptionA: null,
    OptionB: null,
    OptionC: null,
    OptionD: null,
    Correct: null,
    Info: null,
  });
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [numOfSignQuestionsCorrect, setNumOfSignQuestionsCorrect] = useState(0);
  const [numOfRuleQuestionsCorrect, setNumOfRuleQuestionsCorrect] = useState(0);
  const [currentSection, setCurrentSection] = useState("");
  const [optionSelected, setOptionSelected] = useState("None");
  const [showHint, setShowHint] = useState(false);
  const [imageSrc, setImageSrc] = useState(null); // New state for image source

  /**************************** Functions ****************************/
  /******* getCurrentQuestion *******/
  const getCurrentQuestion = async () => {
    let question;
    if (numOfTrafficSignQuestions > currentQuestionIndex) {
      setCurrentSection("trafficSignQuestions");
      question = trafficSignQuestions[questionsList[currentQuestionIndex]];
    } else {
      setCurrentSection("trafficRuleQuestions");
      question = trafficRuleQuestions[questionsList[currentQuestionIndex]];
    }

    console.log("QUESTION: ", question, "@ Index: ", currentQuestionIndex);
    setCurrentQuestion({
      Question: question.Question,
      Image: question.Image,
      OptionA: question.OptionA,
      OptionB: question.OptionB,
      OptionC: question.OptionC,
      OptionD: question.OptionD,
      Correct: question.Correct,
      Info: question.Info,
    });

    if (question.Image) {
      // Dynamically import the image
      const image = await import(`../assets/Questions/${question.Image}`);
      setImageSrc(image.default); // Set the image source
    }
  };

  /******* init *******/
  useEffect(() => {
    setCurrentTimerValue(currentTimerValue * 60);
    getCurrentQuestion();
  }, []);

  /******* NextQuestion *******/
  useEffect(() => {
    if (currentQuestionIndex < questionsList.length) getCurrentQuestion();
    else
      navigate("/result", {
        state: {
          numOfTrafficSignQuestions: numOfTrafficSignQuestions,
          numOfTrafficRuleQuestions: numOfTrafficRuleQuestions,
          numOfSignQuestionsCorrect: numOfSignQuestionsCorrect,
          numOfRuleQuestionsCorrect: numOfRuleQuestionsCorrect,
        },
      });
  }, [currentQuestionIndex]);

  /******* timer *******/
  useEffect(() => {
    if (currentTimerValue <= 0) {
      navigate("/result", {
        state: {
          numOfTrafficSignQuestions: numOfTrafficSignQuestions,
          numOfTrafficRuleQuestions: numOfTrafficRuleQuestions,
          numOfSignQuestionsCorrect: numOfSignQuestionsCorrect,
          numOfRuleQuestionsCorrect: numOfRuleQuestionsCorrect,
          timed: timed,
          timerValue: currentTimerValue,
        },
      });
    }
  }, [currentTimerValue]);

  /******* handlers *******/
  const handleHintClick = () => {
    setShowHint(true);
  };

  const handleNextClick = () => {
    setShowHint(false);
    setOptionSelected("None");
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    if (optionSelected === currentQuestion.Correct) {
      if (currentSection === "trafficSignQuestions") {
        setNumOfSignQuestionsCorrect(numOfSignQuestionsCorrect + 1);
      } else {
        setNumOfRuleQuestionsCorrect(numOfRuleQuestionsCorrect + 1);
      }
    }
  };

  const handleMultipleChoiceOptionSelect = (id) => setOptionSelected(id);

  /**************************** Render ****************************/
  return (
    <body className={styles.body}>
      <div className={styles.quiz}>
        {/**************************** LeftSection ****************************/}
        <section className={styles.leftSection}>
          {/**************************** Image ****************************/}
          {imageSrc ? (
            <img
              className={styles.questionImage}
              alt=""
              src={imageSrc}
            />
          ) : (
            <Spinner />
          )}
          {/**************************** Buttons ****************************/}
          <div className={styles.leftSectionButtonContainer}>
            {showHint || optionSelected !== "None" ? (
              <></>
            ) : (
              <Button
                variant="primary"
                onClick={handleHintClick}
                className={[styles.hintButton, styles.title].join(" ")}
              >
                Hint
              </Button>
            )}
            {optionSelected !== "None" ? (
              <Button
                variant="success"
                onClick={handleNextClick}
                className={[styles.nextButton, styles.title].join(" ")}
                autoFocus
              >
                Next
              </Button>
            ) : (
              <></>
            )}
          </div>

          {/**************************** Hint ****************************/}
          <div className={[styles.hintText, styles.regularText].join(" ")}>
            {(showHint || optionSelected !== "None") &&
            currentQuestion != null &&
            currentQuestion.Info != null
              ? currentQuestion.Info
              : ""}
          </div>
        </section>

        {/**************************** RightSection ****************************/}
        <section className={styles.rightSection}>
          <div className={styles.statusGroup}>
            <StatusGroup
              currentQuestion={currentQuestionIndex}
              max={parseInt(numOfTrafficSignQuestions) + parseInt(numOfTrafficRuleQuestions)}
              correctAmount={
                numOfSignQuestionsCorrect + numOfRuleQuestionsCorrect
              }
              incorrectAmount={
                currentQuestionIndex -
                (numOfSignQuestionsCorrect + numOfRuleQuestionsCorrect)
              }
              timed={timed}
              timerValue={currentTimerValue}
              setCurrentTimerValue={setCurrentTimerValue}
              maxTime={maxTimerValue}
            />
          </div>

          <div className={[styles.question, styles.subheading].join(" ")}>
            {currentQuestion != null && currentQuestion.Question != null
              ? currentQuestion.Question
              : ""}
          </div>

          {optionSelected === "None" ? (
            <div className={styles.multipleChoiceQuestionsGroup}>
              <div
                onClick={() => handleMultipleChoiceOptionSelect("OptionA")}
                className={[
                  styles.multipleChoiceOption,
                  styles.regularText,
                ].join(" ")}
              >
                {currentQuestion != null && currentQuestion.OptionA != null ? (
                  currentQuestion.OptionA
                ) : (
                  <Spinner />
                )}
              </div>
              <div
                onClick={() => handleMultipleChoiceOptionSelect("OptionB")}
                className={[
                  styles.multipleChoiceOption,
                  styles.regularText,
                ].join(" ")}
              >
                {currentQuestion != null && currentQuestion.OptionB != null ? (
                  currentQuestion.OptionB
                ) : (
                  <Spinner />
                )}
              </div>
              <div
                onClick={() => handleMultipleChoiceOptionSelect("OptionC")}
                className={[
                  styles.multipleChoiceOption,
                  styles.regularText,
                ].join(" ")}
              >
                {currentQuestion != null && currentQuestion.OptionC != null ? (
                  currentQuestion.OptionC
                ) : (
                  <Spinner />
                )}
              </div>
              <div
                onClick={() => handleMultipleChoiceOptionSelect("OptionD")}
                className={[
                  styles.multipleChoiceOption,
                  styles.regularText,
                ].join(" ")}
              >
                {currentQuestion != null && currentQuestion.OptionD != null ? (
                  currentQuestion.OptionD
                ) : (
                  <Spinner />
                )}
              </div>
            </div>
          ) : (
            <div className={styles.resultContainer}>
              <div
                className={[styles.correctAnswer, styles.boldText].join(" ")}
              >
                {currentQuestion != null ? (
                  currentQuestion[currentQuestion.Correct]
                ) : (
                  <Spinner />
                )}
              </div>

              {currentQuestion.Correct !== optionSelected ? (
                <div
                  className={[styles.incorrectAnswer, styles.boldText].join(
                    " "
                  )}
                >
                  {currentQuestion != null &&
                  currentQuestion[optionSelected] != null ? (
                    currentQuestion[optionSelected]
                  ) : (
                    <Spinner />
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </section>

        {/**************************** Mobile/Tablet Section ****************************/}
        <section className={styles.mobileSection}>
          {/**************************** Buttons ****************************/}
          <div className={styles.mobileLeftSectionButtonContainer}>
            {showHint || optionSelected !== "None" ? (
              <></>
            ) : (
              <Button
                variant="primary"
                onClick={handleHintClick}
                className={[styles.hintButton, styles.title].join(" ")}
              >
                Hint
              </Button>
            )}
            {optionSelected !== "None" ? (
              <Button
                variant="success"
                onClick={handleNextClick}
                className={[styles.nextButton, styles.title].join(" ")}
                autoFocus
              >
                Next
              </Button>
            ) : (
              <></>
            )}
          </div>

          {/**************************** Hint ****************************/}
          <div
            className={[styles.mobileHintText, styles.regularText].join(" ")}
          >
            {(showHint || optionSelected !== "None") &&
            currentQuestion != null &&
            currentQuestion.Info != null
              ? currentQuestion.Info
              : ""}
          </div>
        </section>
      </div>

      <footer className={styles.footer}>
        <StatusGroup
          currentQuestion={currentQuestionIndex}
          max={parseInt(numOfTrafficSignQuestions) + parseInt(numOfTrafficRuleQuestions)}
          correctAmount={numOfSignQuestionsCorrect + numOfRuleQuestionsCorrect}
          incorrectAmount={
            currentQuestionIndex -
            (numOfSignQuestionsCorrect + numOfRuleQuestionsCorrect)
          }
          timed={timed}
          timerValue={currentTimerValue}
          setCurrentTimerValue={setCurrentTimerValue}
          maxTime={maxTimerValue}
        />
      </footer>
    </body>
  );
};

export default Quiz;
